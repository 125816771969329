import type { User } from '@supabase/supabase-js'
import type { DbUserAccount } from '~~/types/account.types'
import type { Database } from '~~/types/database.types'
import type { AsyncData } from '#app'

type AsyncDataRequestStatus = AsyncData<any, any>['status']['value']

export async function useLazyUserAccounts(): Promise<{
  cacheData: Ref<DbUserAccount[] | null>
  data: ComputedRef<DbUserAccount[]>
  pending: Ref<boolean>
  refresh: () => Promise<any>
  status: Ref<AsyncDataRequestStatus>
}> {
  // Setting up the needed composables
  const supabaseClient = useSupabaseClient<Database>()
  const user: Ref<User | null> = useSupabaseUser()
  const userId = user.value?.id

  // Getting all the dividends from database
  const { data: cacheData } = useNuxtData<DbUserAccount[]>(`accounts-${userId}`)
  const { data, pending, refresh, error, status } = await useLazyAsyncData(`accounts-${userId}`, async () => {
    const { data, error } = await supabaseClient.from('user_accounts').select('*').eq('user_id', userId ?? '')
    if (error) throw error
    return data
  })
  watchEffect(() => {
    if (pending.value === false && error.value !== null) {
      // Managing the error
      useCustomError({
        error: new DatabaseError(DatabaseErrorCodes.SELECT_USER_ACCOUNTS_IMPOSSIBLE),
        cause: error.value,
        data: { userId },
        reportToSentry: true,
        reportToUser: true
      })
    } else {   
      // Sorting the user accounts
      data.value?.sort((a, b) => {
        // TR > IB
        if (a.broker === 'trade-republic' && b.broker === 'interactive-brokers') return -1
        if (a.broker === 'interactive-brokers' && b.broker === 'trade-republic') return 1
        // Having an alias is better than not having one
        if (a.alias && !b.alias) return -1
        if (!a.alias && b.alias) return 1
        // Alphabetical order
        return (a.alias ?? a.name).localeCompare(b.alias ?? b.name)
      })
    }
  })

  // Returning the dividends
  return {
    cacheData,
    data: computed(() => data.value ?? []),
    pending,
    refresh,
    status
  }
}